import { memo } from 'react'
import ReactGA from 'react-ga4'
import { useScreening } from 'context'

const NextBtn = (props) => {

	const { id, step, multiselect, changeStep, options, provideOtherLawAreaOption, abortScreening } = props
	const { trackEvent } = useScreening()

	const validateSelectedOption = () => {
		if(!multiselect) {
			const selectedOption = options.filter(item => item.value === props.values[`question-${id}`]).pop()

			if(selectedOption.action === 'other_areas_option') {
				provideOtherLawAreaOption()
			} else if(selectedOption.action === 'abort') {
				abortScreening()
			} else if(selectedOption.action === 'skip') {
				changeStep(step + 2, selectedOption)
			} else {
				trackGA(step)
				changeStep(step + 1, selectedOption)
			}
		} else {
			trackGA(step)
			changeStep(step + 1)
		}
	}

	const trackGA = () => {
		const label = `Question - ${step}`

		if(process.env.REACT_APP_APP_ENV === 'production') {
			ReactGA.event('next_btn_click', {
				'action': 'click',
				'category': 'automated_screening',
				'label': label,
				'nonInteraction': true,
			})
		}

		trackEvent({
			'event': 'next_btn_click',
			'action': 'click',
			'label': label,
		})
	}

	return (
		<div className='block mt-4'>
			<button type="button" onClick={validateSelectedOption} disabled={!props.values[`question-${id}`] || !props.values[`question-${id}`].length} className='next-btn !bg-white font-medium text-xl leading-5 text-black p-3 py-4 w-full flex justify-center rounded-[12px] items-center'><span>Next</span></button>
		</div>
	)
}

export default memo(NextBtn)