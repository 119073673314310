import ScreeningService from 'services/screeningService'

const getCaseQuestions = (payload) => async(dispatch) => {
	try {
      dispatch({type: 'SHOW_LOADER'})
      const response = await ScreeningService.getCaseQuestions(payload)
      if(response.data.success) {
         dispatch({
            type: 'UPDATE_CASE_QUESTIONNAIRE',
            payload: response.data,
         })
      }
      dispatch({type: 'HIDE_LOADER'})
      return response

   } catch(err) {
      // console.log(err)
      dispatch({type: 'HIDE_LOADER'})
   }  
}

const verifyCase = (payload) => async(dispatch) => {
   try {
      dispatch({type: 'SHOW_LOADER'})
      const response = await ScreeningService.verifyCase(payload)
      dispatch({
         type: 'SET_LEAD_INFO',
         payload: response.data,
      })
      dispatch({type: 'HIDE_LOADER'})
      return response

   } catch(err) {
      // console.log(err)
      dispatch({type: 'HIDE_LOADER'})
   }  
}

const contactAdmin = (id, message) => async(dispatch) => {
   try {
      dispatch({type: 'SHOW_LOADER'})
      const response = await ScreeningService.contactAdmin(id, message)
      dispatch({type: 'HIDE_LOADER'})
      return response

   } catch(err) {
      // console.log(err)
      dispatch({type: 'HIDE_LOADER'})
   }  
}

const signupLead = (payload) => async(dispatch) => {
   try {
      dispatch({type: 'SHOW_LOADER'})
      const response = await ScreeningService.signupLead(payload)
      dispatch({type: 'HIDE_LOADER'})
      return response

   } catch(err) {
      // console.log(err)
      dispatch({type: 'HIDE_LOADER'})
   }  
}

const trackAnalytics = (payload) => {
   try {
      // console.log(payload)
      ScreeningService.trackAnalytics(payload)
   } catch(err) {
      console.log(err)
   }
}

export {
	getCaseQuestions,
   verifyCase,
   contactAdmin,
   signupLead,
   trackAnalytics,
}
